<!-- analysisReport -->
<template>
  <div class="analysisReport-box">
    <analysisReport ref="analysisReport"></analysisReport>
  </div>
</template>

<script>
import analysisReport from '@comp/report/analysisReport'
import { mapState } from 'vuex'
export default {
  name: 'AnalysisReport',
  data() {
    return {}
  },
  props: {},
  components: { analysisReport },
  computed: {
    ...mapState('incrementService', ['magId']),
    taskId() {
      return this.$route.query.taskId || ''
    },
    networkId() {
      return this.$route.query.networkId || ''
    },
    type() {
      return this.$route.query.type || ''
    },
    year() {
      return this.$route.query.year || ''
    },
    issue() {
      return this.$route.query.issue || ''
    }
  },
  mounted() {
    this.$refs.analysisReport.getData(
      this.taskId,
      this.networkId,
      this.type,
      this.year,
      this.issue
    )
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
